.Home__Intro {
  padding: 0 0 14.4rem;

  @include media-md-up {
    padding: 12rem 0;
  }

  img {
    max-width: 100%;
  }
}

.essay time {
  position: relative;
  top: 1px;
}

.case-study-tag {
  color: darken($color_love, 10%);
}

.wip-tag {
  color: darken($color_sunlight, 10%);
}
