/*
  ********************
  "Forest" system
  pm17 brand (1Q23)
  ********************
*/

$color_cream: #FFFCF3;
$color_sand: #837E73;
$color_bark: #666159;
$color_wet_sand: #4D4943;
$color_dark_oak: #292725;
$color_obsidian: #151515;
$color_sunlight: #FFCE6B;
$color_love: #F96ABF;

.color-white     { color: #ffffff; }
.color-cream     { color: $color_cream; }
.color-sand      { color: $color_sand; }
.color-bark      { color: $color_bark; }
.color-wet-sand  { color: $color_wet_sand; }
.color-dark-oak  { color: $color_dark_oak; }
.color-obsidian  { color: $color_obsidian; }
.color-sunlight  { color: $color_sunlight; }
.color-love      { color: $color_love; }

.bg-color-white     { background-color: #ffffff; }
.bg-color-cream     { background-color: $color_cream; }
.bg-color-sand      { background-color: $color_sand; }
.bg-color-bark      { background-color: $color_bark; }
.bg-color-wet-sand  { background-color: $color_wet_sand; }
.bg-color-dark-oak  { background-color: $color_dark_oak; }
.bg-color-obsidian  { background-color: $color_obsidian; }
.bg-color-sunlight  { background-color: $color_sunlight; }
