@mixin transition($type: all, $duration: .22s, $function: ease-in-out){
  transition: $type $duration $function;
}

$sm: 48em;
$md: 64em;
$lg: 75em;

// @custom-media --sm-viewport only screen and (min-width: 48em);
// @custom-media --md-viewport only screen and (min-width: 64em);
// @custom-media --lg-viewport only screen and (min-width: 75em);
@mixin media-up-to-sm {
  @media only screen and (max-width: $sm) { @content; }
}

@mixin media-sm-up {
  @media only screen and (min-width: $sm) { @content; }
}

@mixin media-sm-to-md {
$md: 64em;
  @media only screen and (min-width: $sm) and (max-width: $md) { @content; }
}

@mixin media-sm-to-lg {
  @media only screen and (min-width: $sm) and (max-width: $lg) { @content; }
}

@mixin media-up-to-md {
  @media only screen and (max-width: $md) { @content; }
}

@mixin media-md-up {
  @media only screen and (min-width: $md) { @content; }
}

@mixin media-md-to-lg {
  @media only screen and (min-width: $md) and (max-width: $lg) { @content; }
}

@mixin media-up-to-lg {
  @media only screen and (max-width: $lg) { @content; }
}

@mixin media-lg-up {
  @media only screen and (min-width: $lg) { @content; }
}
