.SideNavigation {
  display: none;

  @include media-md-up { display: block; }

  a {
    color: $color_obsidian;
    display: block;
    margin-bottom: 2em;
    text-decoration: none;
    font-weight: 450;

    &:hover {
      text-decoration: underline;
      color: #000;
    }
  }

  .IconButton {
    position: relative;
    left: -11px;
    margin-bottom: 1.5em;
  }
}
