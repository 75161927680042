body.dark {
  background-color: #151515;
  color: $color_cream;

  @include transition($type: color, $duration: .36s);
  @include transition($type: background-color, $duration: .36s);

  h1, h2, h3, h4, p, li {
    color: $color_cream;
    @include transition($type: color, $duration: .36s);
  }

  a { color: $color_cream; }
  a:hover { color: #fff; }

  .color-sand {
    color: #D9D4C8;
    @include transition($type: color, $duration: .36s);
  }

  .Menu {
    background-color: $color_obsidian;
  }

  .case-study-tag { color: $color_love; }
  .wip-tag        { color: $color_sunlight; }

  .wip {
    background-color: transparentize($color_sunlight, .7);
    border-color: transparentize($color_sunlight, .2);
  }
}
