html, body {
  font-family: $font_copy;
  margin: 0;
  font-size: 62.5%;
}

body {
  color: #666159;
  line-height: 1.42;
  background-color: #FFFCF3;
  font-size: 1.7rem;

  @include transition($type: color, $duration: .36s);
  @include transition($type: background-color, $duration: .36s);
}

h1, h2, h3, h4, h5, h6,
p, button, input, li {
  font-size: 1.7rem;
  margin-bottom: 1.2em;
  font-weight: 350;

  @include transition($type: color, $duration: .36s);
}

h1 { font-size: 3.2rem; }
h2 { font-size: 2.4rem; }
h3 { font-size: 2.1rem; }
h4 { font-size: 1.8rem; }
h5 { font-size: 1.5rem; }

h1, h2 { font-weight: 500; }
h3, h4 { font-weight: 400; }

p, ul, ol, dl, li {
  line-height: 1.58;
}

a {
  color: $color_obsidian;
  margin-bottom: 1em;
}

a:hover {
  color: $color_obsidian;
  text-decoration: underline;
}

a:visited {
  color: $color_dark_oak;
}

strong, b {
  font-weight: 500;
}

em, i {
  font-style: italic;
}

blockquote, q {
  font-family: $font_meta;
  padding-left: 2em;
  font-weight: 300;
  border-left: 4px solid $color_sunlight;
}

figcaption {
  line-height: 1.54;
}


.full-width {
  width: 100%;
}

.text-center { text-align: center; }
.text-left   { text-align: left;   }
.text-right  { text-align: right;  }

.m-0  { margin:        0; }
.mt-0 { margin-top:    0; }
.mr-0 { margin-right:  0; }
.mb-0 { margin-bottom: 0; }
.ml-0 { margin-left:   0; }

.m-1  { margin:        1.2rem; }
.mt-1 { margin-top:    1.2rem; }
.mr-1 { margin-right:  1.2rem; }
.mb-1 { margin-bottom: 1.2rem; }
.ml-1 { margin-left:   1.2rem; }

.m-2  { margin:        1.8rem; }
.mt-2 { margin-top:    1.8rem; }
.mr-2 { margin-right:  1.8rem; }
.mb-2 { margin-bottom: 1.8rem; }
.ml-2 { margin-left:   1.8rem; }

.m-3  { margin:        2.5rem; }
.mt-3 { margin-top:    2.5rem; }
.mr-3 { margin-right:  2.5rem; }
.mb-3 { margin-bottom: 2.5rem; }
.ml-3 { margin-left:   2.5rem; }

.m-4  { margin:        3.6rem; }
.mt-4 { margin-top:    3.6rem; }
.mr-4 { margin-right:  3.6rem; }
.mb-4 { margin-bottom: 3.6rem; }
.ml-4 { margin-left:   3.6rem; }

.m-5  { margin:        4.4rem; }
.mt-5 { margin-top:    4.4rem; }
.mr-5 { margin-right:  4.4rem; }
.mb-5 { margin-bottom: 4.4rem; }
.ml-5 { margin-left:   4.4rem; }

.m-6  { margin:        6.4rem; }
.mt-6 { margin-top:    6.4rem; }
.mr-6 { margin-right:  6.4rem; }
.mb-6 { margin-bottom: 6.4rem; }
.ml-6 { margin-left:   6.4rem; }

.p-0  { padding:        0; }
.pt-0 { padding-top:    0; }
.pr-0 { padding-right:  0; }
.pb-0 { padding-bottom: 0; }
.pl-0 { padding-left:   0; }

.p-1  { padding:        1.2rem; }
.pt-1 { padding-top:    1.2rem; }
.pr-1 { padding-right:  1.2rem; }
.pb-1 { padding-bottom: 1.2rem; }
.pl-1 { padding-left:   1.2rem; }

.p-2  { padding:        1.8rem; }
.pt-2 { padding-top:    1.8rem; }
.pr-2 { padding-right:  1.8rem; }
.pb-2 { padding-bottom: 1.8rem; }
.pl-2 { padding-left:   1.8rem; }

.p-3  { padding:        2.5rem; }
.pt-3 { padding-top:    2.5rem; }
.pr-3 { padding-right:  2.5rem; }
.pb-3 { padding-bottom: 2.5rem; }
.pl-3 { padding-left:   2.5rem; }

.p-4  { padding:        3.6rem; }
.pt-4 { padding-top:    3.6rem; }
.pr-4 { padding-right:  3.6rem; }
.pb-4 { padding-bottom: 3.6rem; }
.pl-4 { padding-left:   3.6rem; }

.d-flex  { display: flex; }
.d-block { display: block; }
.d-none  { display: none; }

.hide-text {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.underlined {
  padding-bottom: 8px;
  border-bottom: 1px solid #D9D4C8;
}

.rounded,
.rounded-1 { border-radius: 4px; }
.rounded-2 { border-radius: 6px; }
.rounded-3 { border-radius: 8px; }
.rounded-4 { border-radius: 12px; }
.rounded-5 { border-radius: 18px; }
.rounded-6 { border-radius: 28px; }

.line-xxs { line-height: 1.0;  }
.line-xs  { line-height: 1.2;  }
.line-sm  { line-height: 1.42; }
.line-md  { line-height: 1.58; }
.line-lg  { line-height: 1.72; }
.line-xl  { line-height: 2.0;  }
