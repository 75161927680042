.Post {
  padding-bottom: 9.6rem;
}

.Post__hero {
  width: 100%;
  height: 192px;
  background-color: $color_sand;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @include media-md-up { height: 320px; }
}

.Post__content {
  font-size: 1.8rem;
  padding-bottom: 9.6rem;
  color: $color_bark;

  h1, h2, h3 {
    color: $color_wet_sand;
  }

  h2, h3 {
    margin-top: 8.8rem;
    font-weight: 600;
  }

  p {
    margin-bottom: 2em;
    font-size: 1.8rem;
  }

  li {
    margin-bottom: 1.5em;
    font-size: 1.8rem;
  }

  a {
    font-size: 1.8rem;
    color: $color_dark_oak;
  }

  ul {
    list-style: disc;
    padding-left: 2em;
    margin-bottom: 3.6rem;
  }

  ol {
    list-style: decimal;
    padding-left: 2em;
    margin-bottom: 3.6rem;
  }

  hr {
    margin: 6.4rem auto;
    background-color: $color_sand;
    height: 1px;
    border-radius: 1px;
    border: none;
  }

  strong, b {
    font-weight: 600;
  }

  img {
    position: relative;
    left: 0;
    max-width: 100%;
    height: auto;
  }

  figure { margin: 6.4rem auto;}
  figcaption { font-size: 1.3rem; color: $color_sand; text-align: center; margin: 1rem; }

  > p:last-of-type:last-child::after {
    content: "∎";
  }
}

.wip {
  padding: 1.2rem;
  background-color: transparentize($color_sunlight, .5);
  border: 1px solid $color_sunlight;
  border-radius: 4px;
  margin-bottom: 4.4rem;

  p { font-size: $font_size_xs; margin: 0; }
}
